import {request} from './request';



// 配套类型
export function getPttype(){
  return request({
    url:'/api/v1/ptgc/getpttype',
  })
}


// 配套工程
export function getPtgc(ptid){
  return request({
    url:'/api/v1/ptgc/getptgc?ptid='+ptid,
  })
}

export function getPtgcById(id){
  return request({
    url:'/api/v1/ptgc/getptgcbyid?id='+id,
  })
}



