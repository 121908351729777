<template>
  <h1></h1>

	<div class="panSgyptgc">		
    	<SGYPTGC />
	</div>



	<div class="panmap">
		<div class="mapleft"></div>
		<div class="maptop"></div>
		
		<div class="map">
    		<MapPTGC ref="child_map" />
		</div>

		<div class="mapright"></div>
		<div class="mapbottom"></div>
	</div>

	<div>		
    	<DIALOG_TC ref="child_dialog_tc" />
    	<DIALOG_Video ref="child_dialog_video" />
    	<DIALOG_VRImg ref="child_dialog_vrimg" />
    	<DIALOG_VRVideo ref="child_dialog_vrvideo" />
	</div>
  
	<div class="panGk">		
    <GK ref="child_gk" />
	</div>

	<div class="panPtxx">		
    <PTXX ref="child_ptxx" />
	</div>

</template>

<script>
import MapPTGC from '@/components/map/MapPtgc.vue'

import SGYPTGC from '@/components/ptgc/sgyptgc.vue'
import GK from '@/components/ptgc/gk.vue'
import PTXX from '@/components/ptgc/ptxx.vue'


import DIALOG_TC from '@/components/dialog/dialog_image.vue'
import DIALOG_Video from '@/components/dialog/dialog_video.vue'
import DIALOG_VRImg from '@/components/dialog/dialog_vrimg.vue'
import DIALOG_VRVideo from '@/components/dialog/dialog_vrvideo.vue'


export default {

	components: {
		MapPTGC,
		SGYPTGC,
		GK,
		PTXX,

		DIALOG_TC,
		DIALOG_Video,
		DIALOG_VRImg,
		DIALOG_VRVideo,
	
	},


	methods: {

		// 选择配套类型
		selectPttype(pttypeid) {
			// console.log("配套类型：" + pttypeid);
			this.$refs.child_gk.selectPttype(pttypeid);
			this.$refs.child_map.selectPttype(pttypeid);
			this.$refs.child_ptxx.selectPttype(pttypeid);			
		},
		selectAlltype() {
			this.$refs.child_map.showAllPOI();			
		},

		// 选择配套工程
		selectPtgc(ptid) {
			// console.log("配套工程：" + ptid);
			this.$refs.child_ptxx.selectPtgc(ptid);
			
			
		},

		// 打开弹出框
		openTC(ptid, imagebox) {
			this.$refs.child_dialog_tc.open(imagebox);
		},
		openVideo(ptid, vadioURL) {
			this.$refs.child_dialog_video.open(vadioURL);
		},
		openVRImg(ptid, vrURL) {
			this.$refs.child_dialog_vrimg.open(vrURL);
		},
		openVRVideo(ptid, vrvadioURL, autoplay) {
			this.$refs.child_dialog_vrvideo.open(vrvadioURL, autoplay);
		},

	}

}
</script>

<style scoped>

.panmap {		
	top: 0px;
	left: 690px;
	width: 2460px;
	height: 945px;
	text-align: center;
  	position: relative;
  	overflow: hidden;
}


.panSgyptgc {	
	top: 79px;
	left: 30px;
	width: 650px;
	height: 837px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}


.panGk {	
	top: 79px;
	left: 3160px;
	width: 650px;
	height: 310px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}


.panPtxx {	
	top: 404px;
	left: 3160px;
	width: 650px;
	height: 510px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}




</style>