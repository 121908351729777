<template>

    <div id="cesiumContainer">        
    </div>

</template>


<script>
import { MapInit, MapViewInit, MapAddDom_Tianditu, MapViewerRemoveAll, MapAddDem, MapDebugCamera, MapFlyToDuration, MapAdd3DTiles, MapAddTiltPhoto } from './mapbase';
import { getPttype, getPtgc } from '../../network/ptgc';
import { getMedia, getCode } from '../../network/media';

export default {
    data () {
        return {
            viewer :'',
            tileset: '',

            // 配套数据
            pttypeData: [],
            pttypeid: 0,
            ptgcData: [],

            ztData: [],
            currentid: 0,  // 当前id（0=未选中）
            
			gisDemSetting: {},

            // 图标大小
            big_width: 54,
            big_height: 59,
            little_width: 36,
            little_height: 39,           

        }
    },
    
    mounted (){
        
        XE.ready().then(this.init); 
        
    },
    
    methods: {
        
        init() {
                

            this.initZT();

            // 
            this.earth = new XE.Earth('cesiumContainer');
            this.viewer = this.earth.czm.viewer;
            window.viewer = this.viewer;
            MapViewInit(this.viewer);

            // 影像和高程
            MapAddDom_Tianditu(this.viewer);
            this.initGisDem();



            // this.initFlyTo(119.15589345462094, 25.463975441022153, 30739.40514037966);
            MapFlyToDuration(this.viewer, 1, 119.15310033018334, 25.438230557057278, 1076.635907030716, 357.9258445739128, -15.739569874715807, 359.9943962851459);
            


            //实体左键点击事件
            var that = this;
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                var pick = viewer.scene.pick(movement.position);
                MapDebugCamera(that.viewer, movement);                // 摄像机信息

                // if (Cesium.defined(pick) && (pick.id.id === 'id')) {
                if (Cesium.defined(pick) ) {             
                    that.$parent.selectPtgc(pick.id.id);

                    // 选中放大
                    that.SelectPin(pick.id.id);

                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
            

            getPttype().then(res=>{
                this.pttypeData = res.data;	

                // console.log("map pttype:" + this.pttypeData);

                this.selectPttype(this.pttypeid);
                                
            })

        },

        initGisDem() {
            
			// Gis配置（16）
			getCode("gis_set", "16").then(res=>{
				this.gisDemSetting = res.data[0];					
                
                console.log(this.gisDemSetting.value1);
                // MapAddDem(this.viewer, "http://39.107.47.88:8015/fjsmart3d/3dtiles/dem/fujian/quansheng");
                MapAddDem(this.viewer, this.gisDemSetting.value1);
			})

        },

        // 
        initZT() {
            for (var ptid=1; ptid<=10; ptid++) {

                this.ztData.push({});
                getPtgc(ptid).then(res=>{

                    if (res.data.length > 0) {
                        var nowid = parseInt(res.data[0].ptid);

                        this.ztData[nowid-1] = res.data;	
                        this.showAllPOI();
                    }
                })

            }

        },

        // 选择配套类型
        selectPttype(pttypeid) {


            this.initFlyTo(119.15589345462094, 25.463975441022153, 30739.40514037966);

            this.pttypeid = parseInt(pttypeid) + 1;   // TODO an:现在是保存index，后续修改后，要进行处理
            
            this.ptgcData = this.ztData[this.pttypeid-1];	

            // console.log("map ptgc:" + this.ptgcData);
            this.showPOI();
                  

            // this.showAllPOI();
        },

        // 显示兴趣点
        showPOI() {
            MapViewerRemoveAll(this.viewer);
                
            for (var i=0; i<this.ptgcData.length; i++) {
                this.AddPin(this.pttypeid, this.ptgcData[i].id, this.ptgcData[i].simplename, this.ptgcData[i].longitude,this.ptgcData[i].latitude);     

            }

            // 默认选中第一个
            this.currentid = 0;
            if (this.ptgcData.length > 0) {
				this.$parent.selectPtgc(this.ptgcData[0].id);
                this.SelectPin(this.ptgcData[0].id);
            }

        },
        
        showAllPOI() {
            this.initFlyTo(119.15589345462094, 25.463975441022153, 30739.40514037966);
            MapViewerRemoveAll(this.viewer);
                
            for (var index=9; index>=0; index--) {

                this.pttypeid = index + 1;               
                this.ptgcData = this.ztData[this.pttypeid-1];	

                for (var i=0; i<this.ptgcData.length; i++) {
                    this.AddPin(this.pttypeid, this.ptgcData[i].id, "", this.ptgcData[i].longitude,this.ptgcData[i].latitude);    
                }
            }
            
            // // 默认选中第一个
            // this.currentid = 0;
            // if (this.ptgcData.length > 0) {
			// 	this.$parent.selectPtgc(this.ptgcData[0].id);
            //     this.SelectPin(this.ptgcData[0].id);
            // }

        },



        // 初始化飞入位置
        initFlyTo(initialLon, lat, height) {
            
            // // 将经纬度转换为世界坐标           
            // var target = Cesium.Cartesian3.fromDegrees(initialLon, lat, height);
            // var offset = new Cesium.Cartesian3(-70.06, -68.64, 6.0908)
            // this.viewer.scene.camera.lookAt(target, offset);

            // 飞入
            this.viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(initialLon, lat, height),   
                orientation: {
                    heading: Cesium.Math.toRadians(0),
                    pitch: Cesium.Math.toRadians(-90),
                    roll: Cesium.Math.toRadians(0)
                },
                complete: function callback() {
                    // 定位完成之后的回调函数
                },
                duration: 1 // 设置飞行持续时间，默认会根据距离来计算
            });

        },

        

        // 添加兴趣点
        AddPin(pttypeid, id, name, lat, lng){
                
            var littleimg = "./images/ptgc/l"+ pttypeid +".png";

            this.viewer.entities.add({
                id: id,
                name: name,
                pttypeid: pttypeid,
                position: Cesium.Cartesian3.fromDegrees(lat, lng),
                label: {
                    text: name,
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    pixelOffset: new Cesium.Cartesian2(0, -this.little_height-20), //偏移量
                },
                billboard: {                    
                    image: littleimg,
                    width: this.little_width,
                    height: this.little_height,
                    
                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,//垂直位置
                    horizontalOrigin :Cesium.HorizontalOrigin.CENTER,//水平位置
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                }
            });
        },

        // 选择兴趣点
        SelectPin(id) {
            

            // 当前如果有选中，就还原
            if (this.currentid > 0) {
                var oldentity = this.viewer.entities.getById(this.currentid);
                                
                var littleimg = "./images/ptgc/l"+ oldentity.pttypeid +".png";

                oldentity.billboard.image = littleimg;
                oldentity.billboard.width = this.little_width;
                oldentity.billboard.height = this.little_height;
                
                oldentity.label.scale = 0.6;
                oldentity.label.fillColor = Cesium.Color.WHITE;
                oldentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.little_height-20);
                oldentity.label.showBackground = false;

                this.currentid = 0;
            }

            // 新选中的放大
            var newentity = this.viewer.entities.getById(id);

            var bigimg = "./images/ptgc/b"+ newentity.pttypeid +".png";

            newentity.billboard.image = bigimg;
            newentity.billboard.width = this.big_width;
            newentity.billboard.height = this.big_height;
            
            
            newentity.label.scale = 0.8;
            newentity.label.fillColor = Cesium.Color.BLACK;
            newentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.big_height-20);
            newentity.label.showBackground = true;
            newentity.label.backgroundColor = new Cesium.Color(0.5, 0.6, 1, 0.8),

            this.currentid = id;

        }




    }               

}



</script>


<style scoped>


</style>