<template>

	<div class="module">
		<div class="caption">
			<p>{{ ptname }}概况</p>
		</div>
				
		<div class="pan">	
			<p class="content">{{ introduction }}</p>
		</div>

	</div>
  
</template>



<script>
import { getPttype } from '../../network/ptgc';

export default {

    data() 
	{ 
		return { 
			pttypeData: [],
			pttypeid: 0,
			
		} 	
	}, 

	computed: {
		
		ptname: function(){
			if (this.pttypeData.length > this.pttypeid) {
				return this.pttypeData[this.pttypeid].ptname;
			}
			else {
				return "";
			}
		},

		introduction: function(){
			if (this.pttypeData.length > this.pttypeid) {
				return this.pttypeData[this.pttypeid].introduction;
			}
			else {
				return "";
			}
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		
		init() {

			getPttype().then(res=>{
				this.pttypeData = res.data;	
								
			})

		},

		// 选择配套类型
		selectPttype(pttypeid) {
			this.pttypeid = pttypeid;
			
			
		},

	}
}

</script>



<style scoped>

.pan {	
	top: 15px;
	left: 10px;
	width: 630px;
	height: 260px;
	border-radius:10px;
  	position:relative;
	background-color: #152a3a;		
}

.content {

	top: 10px;
	left: 10px;
	width: 610px;
	height: 240px;
	position: absolute;	

	overflow: auto;
	font-size: 12px;
	line-height: 18px;
	text-align: left;
	text-indent: 2em;
	color: rgb(255, 255, 255, 0.8);
	
	/* background-color: red; */
}


</style>