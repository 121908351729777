<template>

	<div class="module">
		<div class="caption">
			<p>{{ ptname }}</p>
		</div>
		
		<div class="pan">	
			<p class="content">{{ introduction }}</p>
		</div>
		

		<ul>
			<li>			
				<img src="~@/assets/images/ptgc/btnXCSP.png" alt="" @click="openvideo">
				<div ref="btn1" class="capt btn1" @click="openvideo">
					<p>宣传视频</p>
				</div>
			</li>
			<li>			
				<img src="~@/assets/images/ptgc/btnTC.png" alt="" @click="opentc">
				<div ref="btn2" class="capt btn2" @click="opentc">
					<p>图册</p>
				</div>
			</li>
			<li>		
				<img src="~@/assets/images/ptgc/btnSJVR.png" alt="" @click="openvrimg">				
				<div ref="btn3" class="capt btn3" @click="openvrimg">
					<p>实景VR</p>
				</div>
			</li>
			<li>		
				<img src="~@/assets/images/ptgc/btnVRSP.png" alt="" @click="openvrvideo">
				<div ref="btn4" class="capt btn4" @click="openvrvideo">
					<p>VR视频</p>
				</div>
			</li>
		</ul>

	</div>
  
</template>



<script>
import { getPtgc, getPtgcById } from '../../network/ptgc';
import { getMedia } from '../../network/media';

export default {

    data() 
	{ 
		return { 
			mediaSetting: {},
			ptData: [],
			pttypeid: 0,
			ptid: 0,
			
			
		} 	
	}, 

	computed: {
		
		ptname: function(){
			if (this.ptData.length > 0) {
				return this.ptData[0].ptname;
			}
			else {
				return "";
			}
		},

		introduction: function(){
			if (this.ptData.length > 0) {
				return this.ptData[0].introduction;
			}
			else {
				return "";
			}
		},
	},

	mounted() {
		this.init();
		this.selectPttype(0);
	},

	methods: {
		
		init() {

			// 配套工程（12）的多媒体资源设置
			getMedia("12").then(res=>{
				this.mediaSetting = res.data[0];	
				// console.log(this.mediaSetting.prefix);
				
			})

		},

		// 选择配套类型,默认选择第一个
		selectPttype(pttypeid) {
            this.pttypeid = parseInt(pttypeid) + 1;   // TODO an:现在是保存index，后续修改后，要进行处理
			
			getPtgc(this.pttypeid).then(res=>{
				this.ptData = res.data;	
				this.setState();				
			})
						
		},


		// 选择配套工程
		selectPtgc(ptid) {
			this.ptid = ptid;
			
			getPtgcById(this.ptid).then(res=>{
				this.ptData = res.data;	
				this.setState();
			})
			
		},


		// 设置状态
		setState() {

			// 设置状态
			if (this.ptData[0].video.length > 0) {
				this.$refs.btn1.style.opacity = 1;
			}
			else {
				this.$refs.btn1.style.opacity = 0.5;
			}
			
			if (this.ptData[0].image.length > 0) {
				this.$refs.btn2.style.opacity = 1;
			}
			else {
				this.$refs.btn2.style.opacity = 0.5;
			}
			
			if (this.ptData[0].vrpano.length > 0) {
				this.$refs.btn3.style.opacity = 1;
			}
			else {
				this.$refs.btn3.style.opacity = 0.5;
			}
			
			if (this.ptData[0].vrvideo.length > 0) {
				this.$refs.btn4.style.opacity = 1;
			}
			else {
				this.$refs.btn4.style.opacity = 0.5;
			}		
		},

		// 打开对话框
		opentc() {

			if (this.ptData[0].image.length <= 0) {
				return;
			}


            var imagebox = [];
			var arrtc = this.ptData[0].image.split(",");
			for (var i=0; i<arrtc.length; i++)
			{
				var tcimage = {};
				tcimage.id = i;
				tcimage.idView = this.mediaSetting.prefix + this.ptname + "/" + this.mediaSetting.images + "/" + arrtc[i].trim();

				console.log(tcimage.idView);
				imagebox.push(tcimage);
			}

			this.$parent.openTC(this.ptid, imagebox);
		},
		openvideo() {
			if (this.ptData[0].video.length <= 0) {
				return;
			}

			var vadioURL= this.mediaSetting.prefix + this.ptname + "/" + this.mediaSetting.video + "/" + this.ptData[0].video
			console.log(vadioURL)

			this.$parent.openVideo(this.ptid, vadioURL);
		},
		openvrimg() {
			if (this.ptData[0].vrpano.length <= 0) {
				return;
			}

			var vrURL= this.mediaSetting.prefix + this.ptname + "/" + this.mediaSetting.vrimg + "/" + this.ptData[0].vrpano
			console.log(vrURL)

			this.$parent.openVRImg(this.ptid, vrURL);
		},
		openvrvideo() {
			if (this.ptData[0].vrvideo.length <= 0) {
				return;
			}

			var vrvadioURL= this.mediaSetting.prefix + this.ptname + "/" + this.mediaSetting.vrvideo + "/" + this.ptData[0].vrvideo
			console.log(vrvadioURL)

			this.$parent.openVRVideo(this.ptid, vrvadioURL, "false");
		},
	}


}

</script>



<style scoped>

.pan {	
	top: 15px;
	left: 10px;
	width: 630px;
	height: 235px;
	border-radius:10px;
  	position:relative;
	background-color: #152a3a;		
	
}

.content {

	top: 10px;
	left: 10px;
	width: 610px;
	height: 230px;
	position: absolute;	

	overflow: auto;
	font-size: 12px;
	line-height: 18px;
	text-align: left;
	text-indent: 2em;
	color: rgb(255, 255, 255, 0.8);
	
	/* background-color: red; */
}



ul {
	left: 5px;
	top: 280px;
  	position:absolute;
}

li {
	margin: 5px;
	float: left;
	width: 310px;
	height: 103px;
	/* background-color: red; */
	position: relative;
	cursor: pointer;
}


.capt {	
	top: 28px;
	width: 310px;
	height: 34px;
	text-align: center;
	position: absolute;			
}

.capt p {	
	left: 32px;
	color: #F1F1F1;
  	font-size: 22px;
  	line-height: 34px;
  	text-align: left;
  	position:relative;	
	color: rgb(255, 255, 255, 1);
}

.btn1 {	
	left: 90px;
	background: url("~@/assets/images/main/icoBF.png") no-repeat left; 
	opacity: 1;
}

.btn2 {	
	left: 110px;
	background: url("~@/assets/images/main/icoTC.png") no-repeat left; 
	opacity: 1;
}

.btn3 {	
	left: 98px;
	background: url("~@/assets/images/main/icoVR.png") no-repeat left; 
	opacity: 1;
}

.btn4 {	
	left: 98px;
	background: url("~@/assets/images/main/icoSP.png") no-repeat left; 
	opacity: 1;
}

</style>