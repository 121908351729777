<template>

	<div class="module">
		<div class="pan_line1" @click="clickAll()">	
			<p>配 套 工 程</p>
		</div>

		<div class="pan_line2">
			<ul>
				<li><p index=0>莆田高新区企业管家中心</p></li>
				<li><p index=1>莆田高新区产业链服务中心</p></li>
				<li><p index=2>莆田高新区科技育成中心</p></li>
				<li><p index=3>标准化厂房</p></li>
				<li><p index=4>莆田高新区人才公寓</p></li>
				<li><p index=5>莆田高新区商业综合服务中心</p></li>
				<li><p index=6>莆田高新区小学</p></li>
				<li><p index=7>莆田高新区幼儿园</p></li>
				<li><p index=8>莆田高新区医院</p></li>
				<li><p index=9>莆田高新区商务酒店</p></li>
			</ul>
		</div>
		

	</div>
  
</template>



<script>


export default {

	mounted (){		
		this.init();

	},

	methods: {
		
		init() {
			var that = this;
							
			var labeltabs = document.querySelectorAll(".pan_line2 ul li p")
			for (var i = 0; i<labeltabs.length; i++) {
				labeltabs[i].addEventListener('click', function() {
					
					for (var j = 0; j < labeltabs.length; j++) {
						labeltabs[j].className = '';
					}
					this.className = 'currentqwys';

					var index = this.getAttribute('index');    
					that.$parent.selectPttype(index);
					                   
				})
			}
 
			// that.$parent.selectPttype(0);
		},

		clickAll() {
			
			var labeltabs = document.querySelectorAll(".pan_line2 ul li p")
			for (var i = 0; i<labeltabs.length; i++) {
				labeltabs[i].className = '';
			}

			this.$parent.selectAlltype();
		}
	}

}

</script>



<style scoped>

.pan_line1 {	
	top: 15px;
	left: 15px;
	width: 620px;
	height: 173px;
	position: absolute;		
	border-radius:16px 16px 0 0;	
	background: rgba(0, 0, 0, 0.5) url("~@/assets/images/ptgc/pgtc.png") no-repeat; 	  
}

.pan_line1 p {

	color: rgb(255, 255, 255, 1);
	font-style: oblique;
  	font-size: 34px;
  	line-height: 173px;
}


.pan_line2 {	
	top: 200px;
	left: 15px;
	width: 620px;
	height: 620px;
	position: absolute;		
}

.pan_line2 li {    
	margin: 10px 7px;
	float: left;
    width: 295px;
	height: 106px;
	cursor: pointer;
}

.pan_line2 li p{    
	
  	font-size: 20px;
  	line-height: 106px;
	text-align: center;    
    border-radius: 15px;
	color: rgb(255, 255, 255, 0.8);	

    background-color: rgb(255, 255, 255, 0.025);	

}

.pan_line2 li .currentqwys{    
	color: rgb(0, 0, 0, 1);	
    background-color: rgba(155, 245, 237, 1);	
}



</style>